/*----- 20. Cart page  -----*/

.cart-main-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;

  margin: 0 0 15px;
}

.cart-table-content {
  table {
    border: 1px solid #ebebeb;

    .order-header {
      background-color: $theme-color !important;

      th {
        color: #fff;
      }
    }

    thead > tr {
      border: 1px solid #ebebeb;
      background-color: #f9f9f9;

      th {
        font-size: 14px;
        font-weight: 500;

        padding: 21px 45px 22px;

        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        text-transform: uppercase;

        color: #333;
        border-top: medium none;

        @media #{$lg-layout} {
          padding: 21px 35px 22px;
        }

        @media #{$xs-layout} {
          padding: 21px 20px 22px;
        }
      }
    }

    tbody > tr {
      border-bottom: 1px solid #ebebeb;

      td.product-thumbnail {
        width: 150px;
      }

      td.product-name {
        width: 435px;

        // text-align: left;

        a {
          font-size: 15px;
          font-weight: 500;

          color: #333;

          &:hover {
            color: $theme-color;
          }
        }
      }

      td.product-price-cart {
        width: 435px;

        span {
          font-weight: 500;

          color: #333;

          &.old {
            margin-right: 10px;

            text-decoration: line-through;

            color: #8e8e8e;
          }
        }
      }

      td.product-subtotal {
        font-weight: 500;

        color: #333;
      }

      td.product-quantity {
        width: 435px;

        .cart-plus-minus {
          position: relative;

          display: inline-block;

          width: 110px;
          height: 40px;
          padding: 0;

          .qtybutton {
            font-size: 16px;

            position: absolute;

            float: inherit;

            width: 20px;
            margin: 0;

            cursor: pointer;
            transition: all 0.3s ease 0s;
            text-align: center;

            color: #333;
            border: none;
            background: none;
          }

          .dec.qtybutton {
            top: 0;
            left: 0;

            height: 40px;

            border-right: 1px solid #e5e5e5;
          }

          .inc.qtybutton {
            top: 0;
            right: 0;

            height: 40px;

            border-left: 1px solid #e5e5e5;
          }

          input.cart-plus-minus-box {
            font-size: 14px;

            float: left;

            width: 110px;
            height: 40px;
            margin: 0;
            padding: 0;

            text-align: center;

            color: #333;
            border: 1px solid #e1e1e1;
            background: transparent none repeat scroll 0 0;
          }
        }
      }

      td.product-status {
        color: orange;
      }

      td.product-remove {
        width: 100px;

        a,
        button {
          font-size: 17px;

          margin: 0 13px;

          color: #666;
          border: none;
          background: none;

          &:hover {
            color: $theme-color;
          }
        }
      }

      td.product-wishlist-cart > a,
      td.product-wishlist-cart > button {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;

        display: block;

        margin: 0 auto;
        padding: 10px 15px;

        text-transform: uppercase;

        color: #fff;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: $theme-color;

        &:hover,
        &.active {
          background-color: #333;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      td {
        font-size: 15px;

        padding: 30px 0 30px 30px;

        text-align: center;

        color: #333;
      }
    }
  }
}

.order-details-total {
  background-color: #fff2f0;
  border-radius: 25px;
  padding: 18px 20px 18px 20px;

  .amount_type {
    margin: 38px 20px;
    text-transform: uppercase;

    .amount_module {
      font-weight: 600;
    }

    .amount {
      float: right;
    }

    &:last-child {
      color: red;
    }
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;

  padding: 30px 0 55px;

  @media #{$xs-layout} {
    display: block;

    padding: 30px 0 15px;
  }

  .cart-shiping-update > a,
  .cart-clear > button,
  .cart-clear > a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: inline-block;

    padding: 18px 63px 17px;

    text-transform: uppercase;

    color: #363f4d;
    border-radius: 50px;
    background-color: #f2f2f2;

    @media #{$md-layout} {
      font-size: 13px;

      padding: 18px 25px 17px;
    }

    @media #{$xs-layout} {
      margin: 0 0 15px;
      padding: 18px 40px 17px;
    }

    &:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }

  .cart-clear > button {
    margin-right: 27px;

    cursor: pointer;
    transition: all 0.3s ease 0s;

    border: medium none;

    &:last-child {
      margin-right: 0;
    }

    @media #{$md-layout} {
      margin-right: 15px;
    }
  }
}

.cart-total {
  .box-custom {
    display: flex;
    justify-content: space-between;
  }
}

.cart-tax,
.discount-code-wrapper {
  padding: 45px 30px 50px;

  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f9f9f9;

  &.coupon-code {
    width: 48%;
  }

  @media #{$lg-layout} {
    padding: 45px 18px 50px;
  }

  @media #{$xs-layout} {
    padding: 45px 18px 50px;
  }

  .title-wrap {
    position: relative;

    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: '';
      transition: all 0.4s ease 0s;

      background-color: #e3e1e1;
    }

    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;

      @media #{$lg-layout} {
        font-size: 16px;
      }
    }

    .section-bg-gray {
      position: relative;
      z-index: 99;

      background-color: #f8f9f9;
    }
  }

  .tax-wrapper {
    margin-top: 22px;

    p {
      margin: 0;
    }

    .tax-select-wrapper {
      margin: 5px 0 0;

      .tax-select {
        margin: 0 0 26px;

        label {
          font-size: 14px;

          margin: 0 0 5px;

          color: #242424;
        }

        select {
          font-size: 12px;
          background: #fff none repeat scroll 0 0;
          width: 100%;
          height: 40px;
          padding: 0 50px 0 15px;

          cursor: pointer;

          color: #242424;
          //background: #fff url("../../assets/img/icon-img/cart.png") no-repeat scroll right 18px center;
          border: 1px solid #ebebeb;
          box-shadow: none;

          // -webkit-appearance: none;
          // -moz-appearance: none;
        }

        input {
          height: 40px;

          border: 1px solid #ebebeb;
          background: #fff none repeat scroll 0 0;
        }
      }

      button.cart-btn-2 {
        font-size: 14px;
        font-weight: 500;

        padding: 13px 42px 12px;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-transform: uppercase;

        color: #fff;
        border: medium none;
        border-radius: 50px;
        background-color: $theme-color;

        &:hover {
          background-color: #333;
        }
      }
    }
  }

  .discount-code {
    margin: 21px 0 0;

    p {
      margin: 0 0 15px;
    }

    form {
      input {
        height: 40px;

        padding-left: 10px;

        border: 1px solid #ebebeb;
        background: #fff;
      }

      button.cart-btn-2 {
        font-size: 14px;
        font-weight: 500;
        margin-top: 30px;
        padding: 13px 42px 12px;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-transform: uppercase;

        color: #fff;
        border: medium none;
        border-radius: 50px;
        background-color: $theme-color;

        &:hover {
          background-color: #333;
        }
      }
    }
  }
}

.grand-totall {
  padding: 45px 30px 50px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f9f9f9;

  &.cart-total-box {
    width: 48%;
  }

  @media #{$lg-layout} {
    padding: 45px 18px 50px;
  }

  @media #{$md-layout} {
    margin-top: 30px;
  }

  .title-wrap {
    position: relative;

    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: '';
      transition: all 0.4s ease 0s;

      background-color: #e3e1e1;
    }

    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }

    .section-bg-gary-cart {
      position: relative;
      z-index: 9;

      background-color: #f9f9f9;
    }
  }

  h5 {
    font-size: 14px;

    margin: 36px 0 27px;

    span {
      font-size: 18px;
      font-weight: 500;

      float: right;
    }
  }

  .total-shipping {
    margin: 0 0 27px;
    padding: 28px 0;

    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;

    h5 {
      font-size: 14px;

      margin: 0;
    }

    ul {
      padding: 19px 0 0 0;

      li {
        margin: 0 0 6px;

        list-style: outside none none;

        color: #242424;

        &:last-child {
          margin: 0 0 0;
        }

        input {
          position: relative;
          top: 2px;

          width: 13px;
          height: 13px;
          margin-right: 10px;
          padding: 0;

          cursor: pointer;

          color: #626262;
          border: 1px solid #d7d7d7;
          border-radius: 5px !important;
          background: #e9e9e9 none repeat scroll 0 0;
        }

        span {
          float: right;
        }
      }
    }
  }

  h4.grand-totall-title {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 25px;

    color: $theme-color;

    span {
      float: right;
    }
  }

  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    display: block;

    padding: 18px 10px 17px;

    text-align: center;
    text-transform: uppercase;

    color: #fff;
    border-radius: 50px;
    background-color: $theme-color;

    &:hover {
      background-color: #333;
    }
  }
}

.discount-code-wrapper {
  @media #{$xs-layout} {
    margin: 30px 0;
  }
}

.cart-item-variation {
  margin-top: 10px;

  span {
    display: block;
  }
}

// order Listing Page
.custom-recent-order {
  .table-responsive {
    overflow-x: visible;

    table {
      width: 100%;
      display: flex;
      flex-direction: column;

      thead {
        width: 100%;
        display: flex;

        tr {
          width: 100%;
          display: flex;

          th {
            width: 50%;
            display: flex;
            align-items: flex-start;

            &:last-child {
              justify-content: flex-end;
            }
          }
        }
      }

      tbody {
        tr {
          display: flex;
          align-items: center;

          td {
            width: 20%;
          }
        }
      }
    }
  }
}

.custom-payment-shipping-details {
  width: 100%;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-cart-item {
    .cart-table-content {
      table {
        overflow-x: visible;

        thead {
          tr {
            display: flex;
            padding: 0 14px;

            th {
              width: 16.66667%;
              display: flex;
              flex-direction: column;
              padding: 21px 0 22px;
            }
          }
        }

        tbody {
          tr {
            display: flex;
            padding: 0 14px;
          }

          td {
            width: 16.66667% !important;
            display: flex;
            padding-left: 0 !important;
            padding-top: 15px !important;
            padding-bottom: 15px !important;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }

  .cart-custom-row {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;

      .box-custom {
        .coupon-code {
          margin-top: 30px;
        }
      }
    }
  }

  // order Listing Page
  .custom-recent-order {
    .table-responsive {
      overflow-x: visible;
    }
  }

  // order details page

  .order-details {
    &.cart-table-content {
      overflow-x: visible;

      table {
        thead {
          tr {
            display: flex;
            padding: 0 14px;

            th {
              width: 20%;
              display: flex;
              flex-direction: column;
              padding: 21px 0 22px;
            }
          }
        }

        tbody {
          tr {
            display: flex;
            padding: 0 14px;
          }

          td {
            width: 20% !important;
            display: flex;
            padding-left: 0 !important;
            padding-top: 15px !important;
            padding-bottom: 15px !important;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-cart-item {
    .cart-table-content {
      table {
        overflow-x: visible;
        display: flex;

        thead {
          tr {
            display: none;
          }
        }

        tbody {
          tr {
            display: inline-block;
          }

          td {
            width: 50% !important;
            display: inline-block;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            min-height: 67px;
            padding: 20px !important;

            &.product-thumbnail {
              &::before {
                content: '';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-name {
              &::before {
                content: 'Product Name';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-price-cart {
              &::before {
                content: 'Qty';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-quantity {
              &::before {
                content: 'Unit Price';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-subtotal {
              &::before {
                content: 'Subtotal';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-remove {
              &::before {
                content: 'action';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            .img-fluid {
              height: 151px;
            }
          }
        }
      }
    }
  }

  .cart-custom-row {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;

      .box-custom {
        flex-direction: column;

        .coupon-code {
          // margin-top: 30px;
          width: 100%;
        }

        .cart-total-box {
          // margin-top: 30px;
          width: 100%;
        }
      }
    }
  }

  .custom-recent-order {
    .table-content {
      table {
        tbody {
          tr {
            flex-wrap: wrap;

            td {
              width: 50%;
              padding-left: 0;
              padding-bottom: 10px;
              padding-top: 10px;

              &.product-name {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  // order details page

  .order-details {
    &.cart-table-content {
      overflow-x: visible;
      display: flex;

      table {
        thead {
          tr {
            display: none;
          }
        }

        tbody {
          tr {
            display: inline-block;
          }

          td {
            width: 50% !important;
            display: inline-block;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            text-align: left !important;
            min-height: 67px;
            padding: 20px !important;

            &.product-thumbnail {
              &::before {
                content: '';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-name {
              &::before {
                content: 'Product Name';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-price-cart {
              &::before {
                content: 'Qty';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-quantity {
              &::before {
                content: 'Unit Price';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-subtotal {
              &::before {
                content: 'Subtotal';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            // .img-fluid {
            //   height: 151px !important;
            // }
          }
        }
      }
    }
  }

  .custom-payment-shipping-details {
    width: 100%;
    display: flex;
    flex-direction: column;

    .col-7 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .order-details-total {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 5px 20px 5px 20px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .custom-cart-item {
    .cart-table-content {
      table {
        overflow-x: visible;
        display: flex;

        thead {
          tr {
            display: none;
          }
        }

        tbody {
          tr {
            display: inline-block;
          }

          td {
            width: 50% !important;
            display: inline-block;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            min-height: 67px;
            padding: 20px !important;

            &.product-thumbnail {
              &::before {
                content: '';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-name {
              &::before {
                content: 'Product Name';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-price-cart {
              &::before {
                content: 'Qty';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-quantity {
              &::before {
                content: 'Unit Price';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-subtotal {
              &::before {
                content: 'Subtotal';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-remove {
              &::before {
                content: 'action';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            // .img-fluid {
            //   max-width: 30px;
            // }
          }
        }
      }
    }
  }

  .cart-custom-row {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;

      .box-custom {
        flex-direction: column;

        .coupon-code {
          // margin-top: 30px;
          width: 100%;
        }

        .cart-total-box {
          // margin-top: 30px;
          width: 100%;
        }
      }
    }
  }

  .custom-recent-order {
    .table-content {
      table {
        tbody {
          tr {
            flex-wrap: wrap;

            td {
              width: 50%;
              padding-left: 0;
              padding-bottom: 10px;
              padding-top: 10px;

              &.product-name {
                text-align: center;
              }
            }
          }
        }

        .footer-table-row {
          tr {
            th {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  // order details page

  .order-details {
    &.cart-table-content {
      overflow-x: visible;
      display: flex;

      table {
        thead {
          tr {
            display: none;
          }
        }

        tbody {
          tr {
            display: inline-block;
          }

          td {
            width: 50% !important;
            display: inline-block;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            text-align: left !important;
            min-height: 67px;
            padding: 20px !important;

            &.product-thumbnail {
              &::before {
                content: '';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-name {
              &::before {
                content: 'Product Name';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-price-cart {
              &::before {
                content: 'Qty';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-quantity {
              &::before {
                content: 'Unit Price';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            &.product-subtotal {
              &::before {
                content: 'Subtotal';
                position: relative;
                top: 0;
                left: 0;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: $theme-color;
                float: left;
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }

            // .img-fluid {
            //   max-width: 40px;
            // }
          }
        }
      }
    }
  }

  .custom-payment-shipping-details {
    width: 100%;
    display: flex;
    flex-direction: column;

    .col-7 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .order-details-total {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 5px 20px 5px 20px;
    }
  }
}

.printRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  .cart-page-title {
    margin: 0 !important;
  }

  .print-button {
    background-color: $theme-color;
    border: medium none;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 16px 35px 17px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease 0s;
  }
}

@media print {
  @page {
    size: landscape;
  }

  .cart-main-area {
    padding-top: 0 !important;
  }

  #printPageButton {
    display: none;
  }

  .cart-page-title {
    display: none;
  }
}
