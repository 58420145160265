$gradient: linear-gradient(87.44deg, #74F2BA 1.94%, #6CE4CF 12.28%, #62D1EE 21.68%, #57BCF9 32.52%, #4BA4F8 47.77%, #3B89F7 63%, #2864F6 80.39%, #3F20F5 97.67%);

/* Footer bottom */
#root {
  height: 100%;

  header {
    flex: 0 0 auto;
  }

  article {
    flex: 1 0 auto;
  }

  footer {
    flex: 0 0 auto;
  }
}

/* Show/hide on hover */
.show-parent {
  &:hover .show-child {
    opacity: 1;
  }

  &:hover .hide-child {
    opacity: 0;
  }
}

/* SVG color */
botton {
  &:hover {
    svg { fill: #515151 }
  }

  &:active {
    svg { fill: #414141 }
  }
}

/* user-select */
img {
  user-select: none;
  -webkit-user-drag: none;
}

/* SWIPER */
.swiper {
  &:hover > .swiper-pagination {
    display: block;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    --swiper-navigation-color: #343434;
    opacity: 0.3;
    z-index: 99999 !important;

    &::after {
      font-size: 24px !important;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-pagination {
    background-color: white;
    display: block;
    bottom: -4px !important;
    z-index: 99999 !important;

    .swiper-pagination-bullet {
      display: inline-block;
      position: relative;
      margin: 0 !important;
      padding: 2px;
      width: 8px;
      height: 8px;
      background: transparent;
      mix-blend-mode: normal;
      opacity: 0.1;

      &.swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        opacity: 1;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background-color: #515151;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #515151;
      }
    }
  }

  .small.swiper-slide {
    border: 2px solid transparent;
    border-radius: 8px;
    overflow: hidden;
  }

  .small.swiper-slide-thumb-active {
    border: 2px solid #4801ff;
    border-radius: 8px;
    overflow: hidden;

    img {
      border-radius: 6px !important;
    }
  }
}

.swiper-modal {
  .swiper-wrapper {
    max-height: 800px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 16px;
    color:rgb(255, 255, 255);
    opacity: 0.3;

    &::after {
      font-size: 26px !important;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .swiper-pagination {
    background-color: inherit;
    z-index: 99999 !important;
    top: 16px;
    bottom: unset !important;
    display: flex !important;
    justify-content: space-between;
    padding: 0 24px;

    .swiper-pagination-bullet {
      &.swiper-pagination-bullet,
      &.swiper-pagination-bullet-active {
        width: 100%;

        &::before {
          width: calc(100% - 8px);
          height: 3px;
          border-radius: 2px;
          background: rgb(255, 253, 253);
        }
      }

      &.swiper-pagination-bullet-active {
        &::before {
          background: 'FFFFF';
        }
      }
    }
  }
}

/* lightGallery */

.lg-outer {
  z-index: 99999;

  .lg {
    background: white;

    .lg-toolbar {
      background: transparent !important;
    }

    .lg-icon {
      &:hover {
        color: #00f7b6 !important;
      }
    }

    .lg-thumb, .lg-thumb-outer, .lg-toggle-thumb {
      background-color: #4801FF !important;
    }

    .lg-thumb-item {
      border: none;


      &.active, &:hover {
        border: 3px solid #00f7b6;
      }
    }
  }
}

/* CHAT VOXYS */

.chat-widget {
  max-width: 100%;
}
