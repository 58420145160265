.contentStyle{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    word-wrap: break-word;
    font-style: normal;
    font-family: 'ALS Hauss';
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
    min-width: 670px;
    margin: 0 20px;
}

.cookieImage {
    min-width: 50px;
    min-height: 70px;
    margin-right: 16px;
    padding-bottom: 15px;
}

.buttonCookie{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    min-width: 141px;
    color: rgba(72,1,255,1);
    background: rgba(0,247,182,1);
    font-size: 16px;
    padding: 10px 30px;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-left: 60px;
    margin-right: 24px;
    font-family: 'ALS Wagon';
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

.buttonCookie:hover{
    background: white;
}

.textStyle{
    white-space: pre-line;
    width: 100%;
}

.cookieLink:hover{
    color: white;
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .buttonCookie {
        height: 36px;
        font-size: 14px;
        margin: 0%;
        min-width: 100%;
        padding: 0 20px;
    }

    .buttonWrapperClasses{
        padding-top:20px;
    }

    .contentStyle {
        min-width: 70%;
        font-size: 13px;
        padding: 10px 20px;
    }

    .cookieImage {
        width: 7%;
        height: 7%;
        margin-right: 12px;
    }
}

@media screen and (max-width: 510px) {

    .contentStyle {
        min-width: 100%;}

    .buttonWrapperClasses{
        padding: 0 20px;
        width: 100%;
    }
}