@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_bold.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_medium.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_bold_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold_condensed.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    font-stretch: condensed;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_bold_narrow.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold_narrow.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    font-stretch: semi-condensed;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_bold_expanded.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold_expanded.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    font-stretch: expanded;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_medium_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_condensed.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    font-stretch: condensed;
}

@font-face {
    font-family: "ALS Wagon Medium Condensed";
    src: url('../../../public/assets/fonts/als_wagon_medium_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_condensed.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_medium_narrow.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_narrow.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    font-stretch: semi-condensed;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_medium_expanded.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_expanded.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    font-stretch: expanded;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_regular_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular_condensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-stretch: condensed;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_regular_narrow.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular_narrow.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-stretch: semi-condensed;
}

@font-face {
    font-family: 'ALS Wagon';
    src: url('../../../public/assets/fonts/als_wagon_regular_expanded.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular_expanded.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-stretch: expanded;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-LightItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss Book';
    src: url('../../../public/assets/fonts/ALSHauss-Book.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-Black.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-Bold.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss Hairline';
    src: url('../../../public/assets/fonts/ALSHauss-HairlineItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-HairlineItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-BoldItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-Regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-Medium.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss Book';
    src: url('../../../public/assets/fonts/ALSHauss-BookItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-BlackItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-Light.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-MediumItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss Hairline';
    src: url('../../../public/assets/fonts/ALSHauss-Hairline.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Hairline.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-Thin.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-ThinItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss';
    src: url('../../../public/assets/fonts/ALSHauss-RegularItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ALS Hauss VF';
    src: url('../../../public/assets/fonts/ALSHaussVF.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHaussVF.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
